import { useState, useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { motion } from "framer-motion";
import ChatIcon from "../../Assets/IMG/ChatIcon.svg";
import BellIcon from "../../Assets/IMG/BellIcon.svg";
import ClaudiaAvatar from "../../Assets/IMG/ClaudiaAvatar.png";
import { DefaultContext } from "../Dashboard";

export default function TopNav() {
  const navigate = useNavigate();
  const ConsumerContext = useContext(DefaultContext);
  const [isTopNavOpen, setTopNavOpen] = useState(true);

	useEffect(() => {
    // Load and initialize Tawk_API here
    //@ts-ignore
    const Tawk_API = window?.Tawk_API || {};
    Tawk_API.onLoad = () => {
      // Code to run when Tawk_API is loaded
      // console.log("Tawk_API loaded");
    };

    // Replace 'your-property-id' with your actual Tawk.to property ID
    const Tawk_LoadStart = new Date();
    const s1 = document.createElement("script");
    const s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
		s1.src='https://embed.tawk.to/6790f5ee825083258e0930da/1ii741chk';
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode?.insertBefore(s1, s0);


    // Cleanup function
    return () => {
      // Cleanup or perform actions when the component is unmounted
      // For example, you might want to hide the Tawk.to widget
      //@ts-ignore
      if (window?.Tawk_API) {
        //@ts-ignore
        window?.Tawk_API.hideWidget();
      }
    };
  }, []);

  return (
    <>
      {/* <span
        className="toggle-topnav px-35"
        onClick={() => {
          setTopNavOpen(!isTopNavOpen);
        }}
      >
        <i className={`fal fa-angle-${isTopNavOpen ? "up" : "down"}`}></i>
      </span> */}
      <div className="top-nav-container flex-row">
        {/* <span className="top-nav-button flex-row">
          <img
            onClick={() => navigate("/dashboard/messages")}
            src={ChatIcon}
            alt=""
            className="top-nav-icon pointer"
          />
          <span className="top-nav-count flex-row">
            {ConsumerContext.Notifications.unread}
          </span>
        </span> */}
        {/* <span className="top-nav-button flex-row">
          <img src={BellIcon} alt="" className="top-nav-icon" />
          <span className="top-nav-count flex-row">52</span>
        </span> */}
        <motion.div
          initial={false}
          animate={{
            height: isTopNavOpen ? "100px" : "0px",
          }}
          className="top-nav-right flex-row"
        >
          <img
            src={ConsumerContext.Profile.details.primary.image ?? ""}
            alt=""
            className="top-nav-avatar"
          />
          <div className="top-nav-col flex-column">
            <div className="cinzel top-nav-name">
              {ConsumerContext.Profile.firstname} {ConsumerContext.Profile.lastname}
            </div>
            {/* <div className="cinzel top-nav-name">
              {ConsumerContext.Profile.lastname}
            </div> */}
            <div className="flex-row top-nav-links">
              <span className="top-nav-tag">Parent</span>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
}
